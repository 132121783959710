import React from "react";
import PropTypes from "prop-types";
import { Duration } from "luxon";
import { Link as RouterLink, useNavigate, useSearchParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import Dialog from "@mui/material/Dialog";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import Close from "@mui/icons-material/Close";
import PhonelinkLock from "@mui/icons-material/PhonelinkLock";
import {
  Card,
  CardActions,
  Container,
  Divider,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import { ThumbDown, ThumbUp } from "@mui/icons-material";
import ErrorBoundary from "../../lib/classes/ErrorBoundary";
import CalendarDate from "../../lib/classes/CalendarDate";
import getRandomWord from "../../lib/getRandomWord";
import { enqueueSnackbar } from "../../store/notifications/actions";
import { creditActions, spendCredit } from "../../store/credits/creditsSlice";

const dateOptions = {
  year: "2-digit",
  month: "2-digit",
  day: "2-digit",
  hour: "2-digit",
  minute: "2-digit",
  second: "2-digit",
};

const ConfirmationDialogRaw = (props) => {
  const [params] = useSearchParams();
  const dispatch = useDispatch();
  const { onClose, open, ...other } = props;
  const navigate = useNavigate();

  const handleCancel = () => {
    onClose();
    navigate("/credits/");
  };

  const handleOk = async () => {
    const regex = new RegExp("(prod=)([0-9]{1,2})", "g");
    const regexed = regex.exec(params);
    const prod = regexed[2];
    const mangle = `{ "kind": ${+prod}, "primary_ident": "${props.forward}" }`;
    const realJson = JSON.parse(mangle);

    dispatch(spendCredit(realJson));
    dispatch(creditActions.spendOne(+prod));

    onClose();
    navigate("/credits/");
  };

  return (
    <Dialog
      sx={{ "& .MuiDialog-paper": { width: "80%", maxHeight: 435 } }}
      maxWidth="xs"
      open={open}
      {...other}
    >
      <DialogTitle>Confirm licence creation...</DialogTitle>
      <DialogContent dividers>
        <Typography
          sx={{ fontFamily: "Big Shoulders Text", fontSize: "1.4rem" }}
        >
          Your proposed identifier: {props.forward}
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button
          startIcon={<ThumbDown />}
          variant="contained"
          onClick={handleCancel}
        >
          Cancel
        </Button>
        <Button startIcon={<ThumbUp />} variant="contained" onClick={handleOk}>
          Ok
        </Button>
      </DialogActions>
    </Dialog>
  );
};

ConfirmationDialogRaw.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
};

function CreditsUse() {
  const [dialog, setDialog] = React.useState(false);
  const idInputRef = React.useRef("");
  const dispatch = useDispatch();
  const [params] = useSearchParams();
  const randomWord = getRandomWord();
  const today = new Date(Date.now()).toLocaleDateString(undefined, dateOptions);
  const utc = new Date(Date.now()).toUTCString();
  const regex = new RegExp(
    "prod=([0-9]{1,2})-(.{1,12})-(.{1,4})",
    "g"
  );
  const regexed = regex.exec(params);
  const prodName = regexed[2];
  const prodDuration = regexed[3];
  const realDur = Duration.fromISO(prodDuration).toHuman().replaceAll(" ", "-");
  const suggested = `${prodName}-${realDur}-${randomWord}-${today}`;

  const handleDialogClose = () => {
    setDialog(false);
  };

  const submitHandler = async (event) => {
    event.preventDefault();

    if (idInputRef.current.value.length < 6) {
      idInputRef.current.value = suggested;
      dispatch(
        enqueueSnackbar({
          message: "You must use at least 6 characters for an identifier.",
          options: {
            variant: "warning",
            autoHideDuration: 12000,
            resumeHideDuration: 800,
          },
        })
      );
      return;
    }

    setDialog(true);
  };

  return (
    <ErrorBoundary>
      <Container
        sx={{
          height: "56.8vh",
          width: "56vw",
          textAlign: "center",
          alignContent: "center",
          justifyContent: "center",
        }}
      >
        <Card elevation={2} sx={{ width: "48vw", p: "1px", mb: "1px" }}>
          <CalendarDate />
        </Card>
        <Card
          elevation={5}
          sx={{
            p: "1rem",
            justifyContent: "center",
            width: "48vw",
          }}
        >
          <Typography variant="h4"> Create a new licence </Typography>
          <Divider sx={{ my: 1 }} />
          <Stack direction="row">
            <TextField
              defaultValue={suggested}
              inputMode="text"
              aria-selected
              autoFocus
              inputRef={idInputRef}
              id="outlined-basic"
              label="Account Tag"
              variant="outlined"
              helperText="Set a memorable and unique tag for this licence."
              sx={{ minWidth: "42vw", mx: "1px", p: "1px" }}
            />
          </Stack>
          <Typography>
            Create a new subscription for {prodName} with an initial time from
            claiming of {utc}
          </Typography>
          <CardActions sx={{ justifyContent: "space-between" }}>
            <Button
              LinkComponent={RouterLink}
              to="/credits"
              endIcon={<Close />}
            >
              Cancel
            </Button>
            <Button
              startIcon={<PhonelinkLock />}
              variant="contained"
              sx={{ mx: "1px", p: "1px" }}
              onClick={submitHandler}
            >
              Create
            </Button>
          </CardActions>
        </Card>
      </Container>
      <ConfirmationDialogRaw
        id="confirm-create-licence"
        forward={idInputRef.current.value}
        keepMounted
        open={dialog}
        onClose={handleDialogClose}
      />
    </ErrorBoundary>
  );
}

export default CreditsUse;
